'use client';

import { OrderInterface } from '@redux/interfaces';
import { types } from './types';
import { types as loginTypes } from '@redux/auth/types';

export const INIT_STATE = {
  loading: false,
  error: false,
  loadingContract: false,
  invoiceToPay: {
    account_number: '',
    base_order: 0,
    cesspool_removed_amount_id: 0,
    client_property_id: 0,
    created: '',
    done_date: '',
    id: 0,
    invoice_value: 0,
    operator: '',
    operator_invoice_id: 0,
    payment_deadline: '',
    payment_status: '',
    settlement: null,
    has_first_order_discount: false,
  },
  companies: {
    online_companies: [],
    panorama: [],
    has_permission_to_find_company: false,
  },
  dates: { '-': { estimated: 0, estimated_low: 0 } },
  priceList: {
    provision: 0,
    id: 0,
    type: 0,
    type_code: null,
    prices: {
      barrel_size: 0,
      price: 0,
    },
    extra: [],
  },
  savedComment: null,
  newOrderAdded: false,
  orderIdToDelete: null,
  cancelFailure: null,
  hasAgreement: false,
  successfullyAsked: null,
  askOrderLoading: false,
  askOrder: {
    client_property: null,
    company: { id: null, name: null, phone_nb: null },
    price_list: null,
    planned_date: null,
    estimated_cost: null,
    id: null,
  },
};

export default function reducer(
  state: OrderInterface = INIT_STATE,
  action: any
): OrderInterface {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...INIT_STATE,
      };
    case types.SET_INVOICE_TO_PAY:
      return {
        ...state,
        invoiceToPay: action.data,
      };
    case types.RESET_INVOICE_TO_PAY:
      return {
        ...state,
        invoiceToPay: INIT_STATE.invoiceToPay,
      };
    case types.GET_CLIENT_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_CLIENT_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        companies: action.payload,
        successfullyAsked: null,
      };
    case types.GET_COMPANY_DATES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        hasAgreement: false,
        dates: INIT_STATE.dates,
        priceList: INIT_STATE.priceList,
      };
    case types.GET_COMPANY_DATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.GET_COMPANY_DATES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        dates: action.payload.dates,
        priceList: action.payload.price_list,
        hasAgreement: action.payload.has_agreement,
        savedComment: action.payload.client_order_message,
      };
    case types.GET_COMPANY_DATES_RESET:
      return {
        ...state,
        dates: INIT_STATE.dates,
        priceList: INIT_STATE.priceList,
      };

    case types.ADD_NEW_APP_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.ADD_NEW_APP_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        newOrderAdded: true,
        dates: INIT_STATE.dates,
        priceList: INIT_STATE.priceList,
      };
    case types.ADD_NEW_APP_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        newOrderAdded: false,
      };
    case types.RESET_ORDER_ADDED:
      return {
        ...state,
        newOrderAdded: false,
        successfullyAsked: false,
      };
    case types.SET_ORDER_ID_TO_DELETE:
      return {
        ...state,
        orderIdToDelete: action.payload,
      };
    case types.RESET_ORDER_ID_TO_DELETE:
      return {
        ...state,
        orderIdToDelete: null,
      };
    case types.DELETE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_ORDER_SUCCESS:
      return {
        ...state,
        cancelFailure: false,
        loading: false,
      };
    case types.DELETE_ORDER_FAILURE:
      return {
        ...state,
        cancelFailure: true,
        loading: false,
      };
    case types.RESET_ORDER_DELETE:
      return {
        ...state,
        cancelFailure: null,
      };
    case types.ASK_FOR_DRIVER_IN_COMMUNE_REQUEST:
      return {
        ...state,
        loading: true,
        successfullyAsked: null,
      };
    case types.ASK_FOR_DRIVER_IN_COMMUNE_FAILURE:
      return {
        ...state,
        loading: false,
        successfullyAsked: false,
      };
    case types.ASK_FOR_DRIVER_IN_COMMUNE_SUCCESS:
      return {
        ...state,
        loading: false,
        successfullyAsked: true,
      };
    case types.GET_ORDER_PROPOSITION_DETAILS_REQUEST:
      return {
        ...state,
        askOrderLoading: true,
        askOrder: INIT_STATE.askOrder,
        error: false,
        companies: INIT_STATE.companies,
      };
    case types.GET_ORDER_PROPOSITION_DETAILS_FAILURE:
      return {
        ...state,
        askOrderLoading: false,
        askOrder: INIT_STATE.askOrder,
        error: true,
      };

    case types.GET_ORDER_PROPOSITION_DETAILS_SUCCESS:
      return {
        ...state,
        askOrderLoading: false,
        askOrder: action.payload,
        priceList: action.payload.price_list,
        hasAgreement: action.payload.agreement_exists,
      };

    case types.ACCEPT_ORDER_PROPOSITION_REQUEST:
    case types.CANCEL_ORDER_PROPOSITION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ACCEPT_ORDER_PROPOSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        newOrderAdded: true,
        askOrder: INIT_STATE.askOrder,
        priceList: INIT_STATE.priceList,
      };
    case types.CANCEL_ORDER_PROPOSITION_SUCCESS:
    case types.ACCEPT_ORDER_PROPOSITION_FAILURE:
    case types.CANCEL_ORDER_PROPOSITION_FAILURE:
      return {
        ...state,
        loading: false,
        askOrder: INIT_STATE.askOrder,
        priceList: INIT_STATE.priceList,
      };
    case types.GET_DRIVER_CONTRACT_REQUEST:
      return {
        ...state,
        loadingContract: true,
      };
    case types.GET_DRIVER_CONTRACT_SUCCESS:
    case types.GET_DRIVER_CONTRACT_FAILURE:
      return {
        ...state,
        loadingContract: false,
      };
    default:
      return state;
  }
}
