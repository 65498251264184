'use client';

import { call, put, all } from 'redux-saga/effects';
import RESTService from '@services/RestService';
import { DefaultApiAction } from '@redux/interfaces';
import {
  loginSaga,
  loginFailSaga,
  loginSuccessSaga,
  getUserIdSaga,
  getDocumentsSaga,
  registerSaga,
  registerSuccessSaga,
  registerFailSaga,
  confirmEmail,
  confirmEmailSuccess,
  getEmailSaga,
  getEmailSuccessSaga,
  getEmailFailSaga,
  resetPasswordConfirmFailSaga,
  resetPasswordConfirmSaga,
  resetPasswordConfirmSuccessSaga,
  resetPasswordFailSaga,
  resetPasswordSaga,
  resetPasswordSuccessSaga,
  authClientWithIdsSaga,
  authClientWithIdsSuccessSaga,
  authClientWithIdsFailureSaga,
} from '@redux/auth/sagas';
import {
  changePasswordFailureSaga,
  changePasswordSaga,
  changePasswordSuccessSaga,
  getClientBillingDataSaga,
  getClientBillingDataSuccessSaga,
  getClientContactDataFailureSaga,
  getClientContactDataSaga,
  getClientContactDataSuccessSaga,
  updateClientBillingSaga,
  updateClientBillingSuccessSaga,
  updateClientFailureSaga,
  updateClientSaga,
  updateClientSuccessSaga,
} from '@redux/userSettings/myAccount/sagas';
import {
  getCitiesFailureSaga,
  getCitiesSaga,
  getCitiesSuccessSaga,
  getLocalizationSagaSuccess,
  getProvincesFailureSaga,
  getProvincesSaga,
  getProvincesSuccessSaga,
  getStreetsFailureSaga,
  getStreetsSaga,
  getStreetsSuccessSaga,
} from '@redux/teryt/sagas';
import {
  addCardFailureSaga,
  addCardSaga,
  addCardSuccessSaga,
  deleteCreditCardFailSaga,
  deleteCreditCardSaga,
  deleteCreditCardSuccessSaga,
  getCreditCardSaga,
  getPosIdFail,
  getPosIdSaga,
  payForInvoiceFailureSaga,
  payForInvoiceSaga,
  payForInvoiceSuccessSaga,
} from '@redux/payment/sagas';
import {
  deleteFirebaseTokenSaga,
  getDashboardInfoSaga,
  getDashboardInfoSuccessSaga,
  getUnreadNotificationsSaga,
  getUnreadNotificationsSuccessSaga,
  setFirebaseTokenSaga,
  setReadNotificationsSaga,
} from '@redux/dashboard/sagas';
import {
  addNewTankSaga,
  addNewTankSuccessSaga,
  deleteCurrentTankSaga,
  deleteCurrentTankSuccessSaga,
  getTankDetailsSaga,
  updateTankDataSaga,
  updateTankDataSuccessSaga,
  updateTankLocalizationSaga,
  updateTankLocalizationSuccessSaga,
  updateTankDataFailureSaga,
  deleteCurrentTankFailureSaga,
  assignVirtualControlCodeSaga,
  assignVirtualControlCodeFailureSaga,
  assignVirtualControlCodeSuccessSaga,
  uploadSignedProtocolSuccessSaga,
  uploadSignedProtocolSaga,
  getProtocolSaga,
  getProtocolFailureSaga,
  uploadSignedProtocolFailureSaga,
  getProtocolFileSaga,
  getProtocolFileSuccessSaga,
  getProtocolFileFailureSaga,
  getProtocolSuccessSaga,
  getProtocolDetailsSaga,
  getProtocolDetailsFailureSaga,
  sendProtocolCommentSaga,
  sendProtocolCommentFailureSaga,
  sendProtocolCommentSuccessSaga,
} from '@redux/userCesspools/sagas';
import { getClientPropertySaga } from '@redux/userCesspools/sagas';
import {
  acceptOrderPropositionFailureSaga,
  acceptOrderPropositionSaga,
  acceptOrderPropositionSuccessSaga,
  addNewOrderFailureSaga,
  addNewOrderSaga,
  addNewOrderSuccessSaga,
  askForDriverInCommuneFailureSaga,
  askForDriverInCommuneSaga,
  askForDriverInCommuneSuccessSaga,
  cancelOrderPropositionFailureSaga,
  cancelOrderPropositionSaga,
  cancelOrderPropositionSuccessSaga,
  deleteOrderFailureSaga,
  deleteOrderSaga,
  deleteOrderSuccessSaga,
  getClientCompaniesFailureSaga,
  getClientCompaniesSaga,
  getCompanyDatesSaga,
  getContractFailureSaga,
  getContractSaga,
  getContractSuccessSaga,
  getOrderPropositionDetailsFailureSaga,
  getOrderPropositionDetailsSaga,
} from '@redux/order/sagas';
import {
  addNewExportFailureSaga,
  addNewExportSaga,
  addNewExportSuccessSaga,
  deleteExportSaga,
  deleteExportFailureSaga,
  deleteExportSuccessSaga,
  findCompaniesSaga,
  getHistoryDropSaga,
  updateExportSaga,
  updateExportSuccessSaga,
  updateExportFailureSaga,
  getInvoiceSaga,
  getInvoiceSuccessSaga,
  getInvoiceFailureSaga,
  findCompanyFailureSaga,
  getComplaintSaga,
  getComplaintFailureSaga,
  addComplaintSuccessSaga,
  addComplaintFailureSaga,
  addComplaintSaga,
  findCompaniesSuccessSaga,
  getConfirmationSaga,
  getConfirmationSuccessSaga,
  getConfirmationFailureSaga,
  uploadConfirmationSaga,
  uploadConfirmationFailureSaga,
  uploadConfirmationSuccessSaga,
} from '@redux/myExports/sagas';
import {
  addNewAgreementFailureSaga,
  addNewAgreementSaga,
  addNewAgreementSuccessSaga,
  deleteAgreementFailureSaga,
  deleteAgreementSaga,
  deleteAgreementSuccessSaga,
  downloadAgreementFailureSaga,
  downloadAgreementSaga,
  downloadAgreementSuccessSaga,
  editAgreementFailureSaga,
  editAgreementSaga,
  editAgreementSuccessSaga,
  getAgreementListSaga,
  getAgreementListSuccessSaga,
  signNewAgreementFailureSaga,
  signNewAgreementSaga,
  signNewAgreementSuccessSaga,
} from '@redux/agreements/sagas';

const addSlash = (method: string, id: string | number) => {
  let lastChar = String(id);
  lastChar = lastChar.slice(-1);

  if (method === 'DELETE' && lastChar !== '/' && lastChar !== '=')
    return `${id}/`;
  return id;
};

export function createSaga(prefix: string, endpoint: string, method = 'GET') {
  return function* (action: DefaultApiAction) {
    const data: DefaultApiAction = { ...action };

    const paramsEndpoint = action.id
      ? `${endpoint}/${addSlash(method, action.id)}`
      : endpoint;

    try {
      const response: Response = yield call(
        RESTService.call,
        paramsEndpoint,
        method,
        action.data,
        action.formData
      );

      const headerContentType = response.headers.get('Content-Type');

      let responseBody = {};

      switch (headerContentType) {
        case 'application/json':
          const responseText: string = yield call([response, 'text']);
          responseBody = JSON.parse(responseText);
          break;
        case 'image/jpeg':
        case 'image/png':
        case 'application/pdf':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          const headerContentDisposition = response.headers.get(
            'content-disposition'
          );

          const responseBlob: string = yield call([response, 'blob']);

          responseBody = {
            file: responseBlob,
            name: headerContentDisposition
              ? headerContentDisposition
              : 'Faktura PDF',
          };
      }

      if (!response.ok) {
        yield put({
          type: `${prefix}_FAILURE`,
          payload: {
            code: response.status,
            body: responseBody,
          },
          data,
          id: action.id,
        });
      } else {
        yield put({
          type: `${prefix}_SUCCESS`,
          payload: responseBody,
          id: action.id,
          status: response.status,
        });
      }
    } catch (e) {
      yield put({ type: `${prefix}_FAILURE`, message: e });
    }
  };
}

export default function* rootSaga(getState: any) {
  yield all([
    loginSaga(),
    loginFailSaga(),
    registerSaga(),
    registerFailSaga(),
    registerSuccessSaga(),
    confirmEmail(),
    confirmEmailSuccess(),
    resetPasswordSaga(),
    resetPasswordFailSaga(),
    resetPasswordSuccessSaga(),
    getClientContactDataSaga(),
    getClientContactDataSuccessSaga(),
    getClientContactDataFailureSaga(),
    changePasswordSaga(),
    changePasswordSuccessSaga(),
    changePasswordFailureSaga(),
    updateClientSaga(),
    updateClientSuccessSaga(),
    updateClientFailureSaga(),
    getProvincesSaga(),
    getProvincesSuccessSaga(),
    getProvincesFailureSaga(),
    getCitiesSaga(),
    getCitiesSuccessSaga(),
    getCitiesFailureSaga(),
    getStreetsSaga(),
    getStreetsSuccessSaga(),
    getStreetsFailureSaga(),
    getLocalizationSagaSuccess(),
    addCardSaga(),
    addCardSuccessSaga(),
    getPosIdSaga(),
    getPosIdFail(),
    addCardFailureSaga(),
    getClientBillingDataSaga(),
    getClientBillingDataSuccessSaga(),
    updateClientBillingSaga(),
    updateClientBillingSuccessSaga(),
    getEmailSaga(),
    getEmailSuccessSaga(),
    getEmailFailSaga(),
    getCreditCardSaga(),
    deleteCreditCardSaga(),
    deleteCreditCardFailSaga(),
    deleteCreditCardSuccessSaga(),
    getTankDetailsSaga(),
    getClientPropertySaga(),
    updateTankLocalizationSaga(),
    updateTankDataSaga(),
    deleteCurrentTankSaga(),
    deleteCurrentTankSuccessSaga(),
    updateTankDataSuccessSaga(),
    updateTankLocalizationSuccessSaga(),
    addNewTankSaga(),
    addNewTankSuccessSaga(),
    getDashboardInfoSaga(),
    getClientCompaniesSaga(),
    resetPasswordConfirmSaga(),
    resetPasswordConfirmFailSaga(),
    resetPasswordConfirmSuccessSaga(),
    addNewExportSaga(),
    addNewExportSuccessSaga(),
    addNewExportFailureSaga(),
    findCompaniesSaga(),
    setFirebaseTokenSaga(),
    deleteFirebaseTokenSaga(),
    loginSuccessSaga(),
    getUnreadNotificationsSaga(),
    setReadNotificationsSaga(),
    getUnreadNotificationsSuccessSaga(),
    getCompanyDatesSaga(),
    getHistoryDropSaga(),
    deleteExportSaga(),
    deleteExportSuccessSaga(),
    deleteExportFailureSaga(),
    updateExportSaga(),
    updateExportSuccessSaga(),
    updateExportFailureSaga(),
    getInvoiceSaga(),
    getInvoiceSuccessSaga(),
    getInvoiceFailureSaga(),
    addNewOrderSaga(),
    addNewOrderSuccessSaga(),
    addNewOrderFailureSaga(),
    deleteOrderSaga(),
    deleteOrderSuccessSaga(),
    deleteOrderFailureSaga(),
    payForInvoiceSuccessSaga(),
    payForInvoiceSaga(),
    payForInvoiceFailureSaga(),
    getContractSaga(),
    getContractSuccessSaga(),
    getContractFailureSaga(),
    findCompanyFailureSaga(),
    updateTankDataFailureSaga(),
    getClientCompaniesFailureSaga(),
    deleteCurrentTankFailureSaga(),
    getUserIdSaga(),
    getDocumentsSaga(),
    getDashboardInfoSuccessSaga(),
    downloadAgreementSaga(),
    downloadAgreementSuccessSaga(),
    downloadAgreementFailureSaga(),
    getAgreementListSaga(),
    getAgreementListSuccessSaga(),
    addNewAgreementSaga(),
    addNewAgreementSuccessSaga(),
    addNewAgreementFailureSaga(),
    editAgreementSaga(),
    editAgreementSuccessSaga(),
    editAgreementFailureSaga(),
    deleteAgreementSaga(),
    deleteAgreementSuccessSaga(),
    deleteAgreementFailureSaga(),
    getComplaintSaga(),
    getComplaintFailureSaga(),
    addComplaintSuccessSaga(),
    addComplaintFailureSaga(),
    addComplaintSaga(),
    findCompaniesSuccessSaga(),
    assignVirtualControlCodeSaga(),
    assignVirtualControlCodeFailureSaga(),
    assignVirtualControlCodeSuccessSaga(),
    askForDriverInCommuneFailureSaga(),
    askForDriverInCommuneSaga(),
    acceptOrderPropositionSaga(),
    acceptOrderPropositionFailureSaga(),
    acceptOrderPropositionSuccessSaga(),
    cancelOrderPropositionSaga(),
    cancelOrderPropositionFailureSaga(),
    cancelOrderPropositionSuccessSaga(),
    getOrderPropositionDetailsSaga(),
    getOrderPropositionDetailsFailureSaga(),
    askForDriverInCommuneSuccessSaga(),
    uploadSignedProtocolSuccessSaga(),
    uploadSignedProtocolSaga(),
    getProtocolSaga(),
    getProtocolFailureSaga(),
    uploadSignedProtocolFailureSaga(),
    getProtocolFileSaga(),
    getProtocolFileSuccessSaga(),
    getProtocolFileFailureSaga(),
    getProtocolSuccessSaga(),
    getProtocolDetailsSaga(),
    getProtocolDetailsFailureSaga(),
    sendProtocolCommentSaga(),
    sendProtocolCommentFailureSaga(),
    sendProtocolCommentSuccessSaga(),
    signNewAgreementSaga(),
    signNewAgreementSuccessSaga(),
    signNewAgreementFailureSaga(),
    authClientWithIdsSaga(),
    authClientWithIdsSuccessSaga(),
    authClientWithIdsFailureSaga(),
    getConfirmationSaga(),
    getConfirmationSuccessSaga(),
    getConfirmationFailureSaga(),
    uploadConfirmationSaga(),
    uploadConfirmationSuccessSaga(),
    uploadConfirmationFailureSaga(),
  ]);
}
